.product {
  &:hover {
    cursor: pointer;
    .selected-products & {
      cursor: grab;
    }
    .MuiCardActionArea-focusHighlight {
      opacity: 0.08;
    }
  }
}
.selected-products {
  .MuiCardHeader-content {
    overflow: hidden;
  }
  .MuiCardHeader-subheader {
    width: calc(100% + 48px);
  }
}
.MuiCardActionArea-root {
  margin-top: auto !important;
}
