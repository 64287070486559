.productPlaceholder {
  background-color: #eee;
  border-radius: 4px;
  border: 1px dashed #9e9e9e;
  overflow: hidden;
  .MuiPaper-root {
    opacity: 0;
  }
}

.text {
  margin: auto;
  position: absolute;
  width: 100%;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
}
