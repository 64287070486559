.product {
  display: flex;
  flex-direction: column;
  &:hover {
    cursor: pointer;
    .selected-products & {
      cursor: grab;
    }
    .MuiCardActionArea-focusHighlight {
      opacity: 0.08;
    }
  }
  width: 100%;
}

.imageContainer {
  img {
    width: 100%;
    object-fit: cover;
  }
}

.MuiCardActionArea-root {
  margin-top: auto !important;
}

.cardWrapper {
  height: 100%;
  display: flex;
}

.thumbnail {
  padding-bottom: 100%;
  margin-top: auto;
}

.cardContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  h3 {
    flex-grow: 1;
  }
  p,
  span {
    font-size: 18px;
  }
}
